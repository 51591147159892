@import 'base';
@import 'elements';
@import 'helpers';
@import 'config';

html, body {
    font-family: 'Century Gothic', sans-serif;
    margin: 0;
    line-height: $base-line-height;
    background: $white;
    color: $black;
}

.mat-form-field {
    width: 100%;
}

.iti {
    width: 100%;
    display: inline-flex;
}

.input-tel {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    height: 3.472rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);

    &:hover, &:focus {
        border: 2px solid rgba(63, 119, 191, 0.87);
    }
}

.iti .dropdown-menu.country-dropdown {
    width: 18.75rem;
}

.iti input#country-search-box {
    height: 1.875rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
}

.iti .iti__country-name, .iti .iti__dial-code {
    font-size: 0.875rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
    border: 2px solid #D54E0C;
}

.invalid-intl {
    color: #D54E0C;
    font-size: 10.5px;
    font-weight: 400;
}

.mat-checkbox-layout .mat-checkbox-label {
    white-space: normal;
}

.input-success {
    border-color: $success;
}

.input-error {
    border-color: $warn;

    &:focus, &:invalid:focus {
        box-shadow: $warn-shadow;
    }
}