* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
}

html {
  font-size: 16px;
  line-height: 1.3;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

button {
  display: block;
}

.bg-image {
  width: 100%;
  height: 6.563rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  color: #ffffff;
}

.event-type {
  text-align: center;
  font-weight: bold;
  text-shadow: 0px 3px 4px #00000076;
  opacity: 1;
  font-size: 0.75rem;
  text-transform: uppercase;
  margin-bottom: 0.75rem;
}

@media (min-width: 575.98px) {
  .bg-image {
    height: 13.125rem;
  }

  .event-type {
    font-size: 0.938rem;
    margin-bottom: 0.938rem;
  }
}
@media (min-width: 767.98px) {
  .bg-image {
    height: 19.688rem;
  }

  .event-type {
    font-size: 1.125rem;
    margin-bottom: 1.125rem;
  }
}
.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-normal {
  font-weight: normal !important;
}

.text-semi-bold {
  font-weight: 500 !important;
}

.text-bold {
  font-weight: 700 !important;
}

.text-italic {
  font-style: italic;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.font-italic {
  font-style: italic;
}

.font-38 {
  font-size: 2.375rem !important;
}

.font-29 {
  font-size: 1.813rem !important;
}

.font-27 {
  font-size: 1.688rem !important;
}

.font-24 {
  font-size: 1.5rem !important;
}

.font-23 {
  font-size: 1.438rem !important;
}

.font-16 {
  font-size: 1rem !important;
}

.font-14 {
  font-size: 0.875rem !important;
}

.font-12 {
  font-size: 0.75rem !important;
}

.font-10 {
  font-size: 0.625rem !important;
}

.vh-100 {
  height: 100vh;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.w-46 {
  width: 46%;
}

.w-30 {
  width: 30%;
}

.h-auto {
  height: fit-content;
}

.h-100 {
  height: 100%;
}

.h-50 {
  height: 50%;
}

.py-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mb-6 {
  margin-bottom: 2rem !important;
  object-fit: cover;
}

.text-primary {
  color: #0D4B79;
}

.text-accent {
  color: #803790;
}

.text-warn {
  color: #E84A20;
}

.text-white {
  color: #FFFFFF;
}

.text-white-light {
  color: #F2F2F2;
}

.text-white-dark {
  color: #E6E6E6;
}

.text-black-light {
  color: #2E2E2E;
}

.text-gray {
  color: #D9D4CE;
}

.text-gray-light {
  color: #F2F2F2;
}

.text-red {
  color: #BD3B5C;
}

.bg-primary {
  background-color: #0D4B79;
}

.bg-accent {
  background-color: #803790;
}

.bg-warn {
  background-color: #E84A20;
}

.bg-white {
  background-color: #FFFFFF;
}

.bg-white-dark {
  background-color: #E6E6E6;
}

.bg-primary-gradient {
  background: linear-gradient(90deg, #0b3d62 0%, #0d4b79 35%, #0f5990 93%);
}

.bg-gray {
  background: #D9D4CE;
}

.bg-gray-light {
  background: #F2F2F2;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.overflow-x {
  overflow-x: scroll;
}

.form-row .form-2, .form-row .form-3, .form-row .form-big {
  margin-right: 0.75rem;
}
.form-row .form-2:last-child, .form-row .form-3:last-child, .form-row .form-big:last-child {
  margin-right: 0;
}

.form-2 {
  width: 47.9%;
}

.form-3 {
  width: 32%;
}

.form-big {
  width: 71.1%;
}

.form-small {
  width: 24.7%;
}

.form-full {
  width: 100%;
}

.form-fourth {
  width: 72%;
}

.form-tel {
  display: inline-block;
  position: relative;
}

@media (min-width: 576px) {
  .form-2 {
    width: 48.8%;
  }

  .form-big {
    width: 73%;
  }

  .form-fourth {
    width: 40%;
  }
}
@media (min-width: 768px) {
  .form-2 {
    width: 49.1%;
  }

  .form-3 {
    width: 32%;
  }

  .form-big {
    width: 73.6%;
  }

  .form-small {
    width: 24.7%;
  }

  .form-fourth {
    width: 30%;
  }
}
@media (min-width: 992px) {
  .form-2 {
    width: 49.3%;
  }

  .form-big {
    width: 74%;
  }

  .form-fourth {
    width: 22%;
  }
}
@media (min-width: 1200px) {
  .form-2 {
    width: 49.4%;
  }
}
/* .mat-form-field-appearance-outline.mat-form-field-disabled
    .mat-form-field-label {
    color: rgba(0, 0, 0, 0.6);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled
    .mat-form-field-infix::after {
    color: #101010;
} */
html, body {
  font-family: "Century Gothic", sans-serif;
  margin: 0;
  line-height: 1.2;
  background: #FFFFFF;
  color: #101010;
}

.mat-form-field {
  width: 100%;
}

.iti {
  width: 100%;
  display: inline-flex;
}

.input-tel {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  height: 3.472rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
}
.input-tel:hover, .input-tel:focus {
  border: 2px solid rgba(63, 119, 191, 0.87);
}

.iti .dropdown-menu.country-dropdown {
  width: 18.75rem;
}

.iti input#country-search-box {
  height: 1.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
}

.iti .iti__country-name, .iti .iti__dial-code {
  font-size: 0.875rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 2px solid #D54E0C;
}

.invalid-intl {
  color: #D54E0C;
  font-size: 10.5px;
  font-weight: 400;
}

.mat-checkbox-layout .mat-checkbox-label {
  white-space: normal;
}

.input-success {
  border-color: #0000001f;
}

.input-error {
  border-color: #E84A20;
}
.input-error:focus, .input-error:invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 74, 32, 0.2);
}