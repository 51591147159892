.bg-image {
    width: 100%;
    height: 6.563rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    color: #ffffff;
}

.event-type {
    text-align: center;
    font-weight: bold;
    text-shadow: 0px 3px 4px #00000076;
    opacity: 1;
    font-size: 0.75rem;
    text-transform: uppercase;
    margin-bottom: 0.75rem;
}

@media (min-width: 575.98px) {
    .bg-image {
        height: 13.125rem;
    }

    .event-type {
        font-size: 0.938rem;
        margin-bottom: 0.938rem;
    }
}

@media (min-width: 767.98px) {
    .bg-image {
        height: 19.688rem;
    }

    .event-type {
        font-size: 1.125rem;
        margin-bottom: 1.125rem;
    }
}