.font-38 {
    font-size: 2.375rem !important;
}

.font-29 {
    font-size: 1.813rem !important;
}

.font-27 {
    font-size: 1.688rem !important;
}

.font-24 {
    font-size: 1.5rem !important;
}

.font-23 {
    font-size: 1.438rem !important;
}

.font-16 {
    font-size: 1rem !important;
}

.font-14 {
    font-size: 0.875rem !important;
}

.font-12 {
    font-size: 0.75rem !important;
}

.font-10 {
    font-size: 0.625rem !important;
}