.form-row {
    .form-2, .form-3, .form-big {
        margin-right: 0.75rem;

        &:last-child {
            margin-right: 0;
        }
    }
}

.form-2 {
    width: 47.9%;
}

.form-3 {
    width: 32%;
}

.form-big {
    width: 71.1%;
}

.form-small {
    width: 24.7%;
}

.form-full {
    width: 100%;
}

.form-fourth {
    width: 72%;
}

.form-tel {
    display: inline-block;
    position: relative;
}

@media (min-width: 576px) {
    .form-2 {
        width: 48.8%;
    }

    .form-big {
        width: 73%;
    }

    .form-fourth {
        width: 40%;
    }
}

@media (min-width: 768px) {
    .form-2 {
        width: 49.1%;
    }
    
    .form-3 {
        width: 32%;
    }
    
    .form-big {
        width: 73.6%;
    }
    
    .form-small {
        width: 24.7%;
    }

    .form-fourth {
        width: 30%;
    }
}

@media (min-width: 992px) {
    .form-2 {
        width: 49.3%;
    }

    .form-big {
        width: 74%;
    }

    .form-fourth {
        width: 22%;
    }
}

@media (min-width: 1200px) {
    .form-2 {
        width: 49.4%;
    }
}