.cursor-pointer {
    cursor: pointer;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.overflow-x {
    overflow-x: scroll;
}