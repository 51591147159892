.text-center {
    text-align: center !important;
}

.text-justify {
    text-align: justify !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-normal {
    font-weight: normal !important;
}

.text-semi-bold {
    font-weight: 500 !important;
}

.text-bold {
    font-weight: 700 !important;
}

.text-italic {
    font-style: italic;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.font-italic {
    font-style: italic;
}