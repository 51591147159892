* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
}

html {
    font-size: 16px;
    line-height: 1.3;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

button {
    display: block;
}