.vh-100 {
    height: 100vh;
}

.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.w-46 {
    width: 46%;
}

.w-30 {
    width: 30%;
}

.h-auto {
    height: fit-content;
}

.h-100 {
    height: 100%;
}

.h-50 {
    height: 50%;
}

.py-6 {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.mr-2 {
    margin-right: .5rem !important;
}

.mb-6 {
    margin-bottom: 2rem !important;
    object-fit: cover;
}