/* .mat-form-field-appearance-outline.mat-form-field-disabled
    .mat-form-field-label {
    color: rgba(0, 0, 0, 0.6);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled
    .mat-form-field-infix::after {
    color: #101010;
} */
