$primary: #0D4B79;
$primary-dark: #1669bb;
$primary-light: #2083e4;

$accent: #803790;
$accent-dark: #5c8c31;
$accent-light: #74b23f;

$warn: #E84A20;
$warn-dark: #CD4800;
$warn-light: #FF5A00;

$white: #FFFFFF;
$white-dark: #E6E6E6; 
$white-light: #F2F2F2; 
$black: #101010;
$black-dark: #141414;
$black-light: #2E2E2E;

$gray: #D9D4CE;
$gray-light: #F2F2F2;

$yellow-light: #ffff4a;
$yellow-light-hover: #ffff64;

$moderate-pink: #BD3B5C;

$success: #0000001f;

$primary-gradient: linear-gradient(90deg, rgba(11,61,98,1) 0%, rgba(13,75,121,1) 35%, rgba(15,89,144,1) 93%);

$warn-shadow: 0 0 0 0.2rem rgba(232, 74, 32, 0.2);
